import React, { createContext, useContext, useState, useEffect } from 'react';

const RamadanDataContext = createContext();

export const useRamadanData = () => useContext(RamadanDataContext);

export const RamadanDataProvider = ({ children }) => {
  const [day, setDay] = useState(() => {
    const storedDay = localStorage.getItem("day");
    return storedDay ? JSON.parse(storedDay) : 1;
  });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("token") !== null;
  });
  const setLoggedInStatus = (status) => {
    setIsLoggedIn(status);
  };

  const changeDay = (newDay) => {
    localStorage.setItem("day", newDay);
    setDay(newDay);
  };

  const updateDataForDay = (day, newData) => {
    console.log('updating data for day', day, newData)
    setData({
      ...data,
      [day]: {
        ...data[day],
        ...newData,
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true before fetching data

        const response = await fetch("https://admin.masjidinthepark.org/api/data/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${localStorage.getItem("token")}`,
          },
        });

        const fetchedData = await response.json();

        if (response.ok) {
          if (fetchedData.length > 0) {
            setData(fetchedData[0].data);
          } else {
            console.error("Fetching data failed: No data found");
          }
        } else {
          console.error("Fetching data failed:", fetchedData.error);
        }
      } catch (error) {
        console.error("Fetching data failed:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    if (isLoggedIn && localStorage.getItem("token")) {
      fetchData();
    }
  }, [isLoggedIn]);



  useEffect(() => {
    const updateData = async (updatedData) => {
      try {
        setLoading(true); // Set loading to true before updating data

        const response = await fetch(`https://admin.masjidinthepark.org/api/data/`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            user: localStorage.getItem("name"),
            data: { [day]: updatedData },
            last_day: day, // Include the current day in the request
          }),
        });

        const responseData = await response.json();

        if (!response.ok) {
          console.error("Updating data failed:", responseData.error);
        }
      } catch (error) {
        console.error("Updating data failed:", error);
      } finally {
        setLoading(false); // Set loading to false after updating data
      }
    };

    // Trigger the update even if there is no data for the current day
    updateData(data[day] || {});
  }, [day, data]);

  const tipsOfTheDay = [
    "Take your multivitamins at suhoor to ensure your body obtains all its required nutrients throughout the day.",
    "Avoid eating oily foods after iftar and sour foods during suhoor.",
    "Start working on your most important tasks right after Fajr prayer for maximum benefit.",
    "Break your fast with just dates and water — then go to pray Maghrib before you sit down to eat your meal.",
    "Remember to stay hydrated! Drink plenty of water at suhoor and iftaar.",
    "Don’t forget to continue staying active during Ramadan — even if that means going for a short walk after iftaar or suhoor.",
    "Avoid eating foods that are deep fried and high in sugar and sodium.",
    "If you take a short nap during the day don’t overdo it. Use the majority of your day for ibaadah.",
    "Do not overeat during suhoor and iftaar.",
    "Break your day down into manageable chunks and block off time for worshipping Allah.",
    "Reach for the fruits and vegetables that’ll help with hydration throughout the day and night.",
    "Write down your duas — it makes it easier to repeat them multiple times throughout the month.",
    "Pray each prayer exactly on time. Make wudhu in advance and wait for each prayer a few minutes before it begins.",
    "Focus on taking in foods that are rich in complex carbohydrates (ex: barley, whole wheat toast, quinoa, peas, beans, etc.)",
    "If someone annoys or even insults you, don’t get into an argument. Instead say, “let’s agree to disagree” — and then change the subject.",
    "During the hottest part of the day, stay in cool areas (indoors or in the shade) and limit your physical activity.",
    "Use Allah’s unique names to make your duas extra special and meaningful.",
    "Improve your conversational manners by fully listening to what others have to say, instead of waiting to speak.",
    "You can learn a lot about a person by what they gift to you/ others.",
    "Remind yourself that the last ten nights of Ramadan only happen once a year — and give it your all.",
    "Reading Quran after Fajr is a great habit to develop; it builds discipline and spiritual endurance.",
    "Sincerely asking Allah to help you wake up early for the purpose of worshipping him does wonders.",
    "Get out of the bed immediately once you hear your alarm/ athaan ring.",
    "Think of someone who helped shape your life and make sincere dua for them by name today.",
    "To make daily dhikr easier, download a tasbeeh app!",
    "Try to eat most of your carbohydrates (the complex kind!) for suhoor, and most of your protein (the non-fried kind!) after iftaar.",
    "If you overeat during iftaar, it’ll be extremely difficult to stay awake during the night. Try to eat more balanced meals in the last ten nights.",
    "Make a list of all the habits you wish to carry forth and stop after Ramadan ends. Next to each habit, explain how you plan on doing it.",
    "Making a habit of drinking sugary drinks during Ramadan will only cause further problems after the month ends.",
    "Positivity is contagious! Spread the joy and have an awesome Eid!"
  ];
  const quotesOfTheDay = [
    "Whoever would like his provision to be increased and his lifespan extended, let him uphold his ties of kinship. — The Prophet Muhammad (PBUH) / Bukhari",
    "Whoever does not give up forged speech and evil actions, Allah is not in need of his leaving his food and drink (i.e. Allah will not accept his fasting.) — The Prophet Muhammad (PBUH) / Bukhari",
    "“Truly, Allah loves those who turn to Him constantly and He loves those who keep themselves pure and clean — The Holy Quran 2:222",
    "The most beloved people to Allah are those who are most beneficial to the people. — The Prophet Muhammad (PBUH) / Al-Albani",
    "A man said, “Messenger of Allah (PBUH) what rights can parents demand from their children?” He replied, “They are your Paradise and your Hell.” — The Prophet Muhammad (PBUH) / Tirmidhi",
    "The two Rak`ah before the Fajr prayer are better than this world and all it contains. — The Prophet Muhammad (PBUH) / Muslim",
    "Whoever prays Fajr in congregation then sits remembering Allah until sunrise then prays two units of prayer, has a complete reward of Hajj and Umrah. — The Prophet Muhammad (PBUH) / Tirmidhi",
    "The signs of a hypocrite are three: Whenever he speaks, he tells a lie; and whenever he promises, he breaks his promise; and whenever he is entrusted, he betrays (proves to be dishonest). — The Prophet Muhammad (PBUH) / Bukhari",
    "Whoever suppresses his rage, even though he could fulfill his anger if he wished, then Allah will secure his heart on the Day of Resurrection. — The Prophet Muhammad (PBUH) / Al-Albani",
    "Feed the hungry, visit the sick and free the captives. — The Prophet Muhammad (PBUH) / Bukhari",
    "Whoever provides food for breaking of the fast of a fasting person receives the reward of the fasting person, without the reward of the fasting person being reduced in any way. — The Prophet Muhammad (PBUH) / Tirmidhi",
    "Whoever says SubhanAllaahi wa bihamdihi (Glorified is Allah and praised is He) one hundred times a day, will have his sins forgiven even if they are like the foam of the sea. — The Prophet Muhammad (PBUH) / Bukhari",
    "Whoever builds a mosque for Allah, then Allah will build for him a similar house in Paradise. — The Prophet Muhammad (PBUH) / Bukhari, Muslim",
    "Whoever covers the faults of a Muslim, Allah will cover his faults in this world and in the Hereafter. Allah helps the servant as long as he helps his brother. — The Prophet Muhammad (PBUH) / Muslim",
    "Politeness with people is charity. — The Prophet Muhammad (PBUH) / Sahih Ibn Hibban",
    "Whoever gives relief to his debtor or waives his debt completely, then he will be in the shade of the Throne on the Day of Resurrection. — The Prophet Muhammad (PBUH) / Musnad Ahmad",
    "If you do a bad deed, then follow it up with a good deed. — The Prophet Muhammad (PBUH) / Ibn Hibban",
    "He has not thanked Allah who has not thanked people. — The Prophet Muhammad (PBUH) / Bukhari",
    "Give each other gifts and you will love each other. — The Prophet Muhammad (PBUH) / Tirmidhi",
    "Search for the Night of Qadr in the odd nights of the last ten days of Ramadan. — The Prophet Muhammad (PBUH) / Bukhari",
    "If one among you shows slackness and weakness (in the earlier pa rt of Ramadan), it should not be allowed to prevail upon him in the last week. — The Prophet Muhammad (PBUH) / Muslim",
    "When the last ten nights (of Ramadan) would begin, the Messenger of Allah (sws) would keep awake at night (for prayer and devotion), awaken his family, and prepare himself to be more diligent in worship. — The Prophet Muhammad (PBUH) / Muslim",
    "When a Muslim visits his sick brother, he is as if he were there in the Jannah as much as his visit lasts. — The Prophet Muhammad (PBUH) / Muslim",
    "Whoever died and he ought to have fasted (the missed days of Ramadan) then his guardians must fast on his behalf. — The Prophet Muhammad (PBUH) / Bukhari",
    "Tasbihah in Ramadan is better than a thousand tasbihah in other than it. — The Prophet Muhammad (PBUH) / Tirmidhi",
    "Eat Sahur, for in Sahur there is blessing. — The Prophet Muhammad (PBUH) / An-Nasai",
    "Indeed, We sent the Qur’an down during the Night of Decree. And what can make you know what is the Night of Decree? The Night of Decree is better than a thousand months. — Surah 97, Verses 1-3",
    "Allah, may He be blessed and exalted, says: Fasting is for me & I shall reward for it. The fasting person has two moments of joy: When he breaks his fast and when he meets his Lord. — The Prophet Muhammad (PBUH) / An-Nasai",
    "For those who fast there is a gate in Paradise called Ar-Rayyan, through which no one but they will enter. When the last of them has entered it, it will be closed. Whoever enters through it will drink, and whoever drinks will never thirst again. — The Prophet Muhammad (PBUH) / An-Nasai",
    "Whoever fasts Ramadan, then follows it with six from Shawwal, then that is (equal in reward) to fasting everyday. — The Prophet Muhammad (PBUH) / Tirmidhi"
  ];
  const deedsOfTheDay = ["Share a beneficial Islamic document, video, quote or image to inspire your friends and family",
    "Stop yourself from saying anything negative about someone — make dua for them instead!",
    "Try to remain in a state of wudhu’ for the entire day — freshening it up immediately when needed.",
    "Buy a meal for a homeless person — or someone you see in need.",
    "Be extra kind to your parents/ guardians/elders today. Go out of your way for their comfort.",
    "Be the one serving dates and water to those breaking their fasts around you.",
    "Pray Salat ul-Ishraq (ref: hadith above) after Fajr prayer.",
    "Invite someone who you are not particularly close to over for iftar.",
    "Give your neighbour some company — with some delicious food included!",
    "If you know anyone who is sick, then visit them and give them your time and company.",
    "Give food to a fasting person to help break their fast.",
    "Do some extra dhikr today after each fard prayer (ref: hadith above)",
    "Go to the mosque closest to you and donate what ould’ve been your lunch/coffee money for the day",
    "Make dua for someone who has hurt you — ask Allah to guide, forgive and bless them.",
    "Be extra polite and kind to those who test your patience.",
    "Waive the debt of someone who owes you money — then ask Allah to forgive them and accept your deed.",
    "Cancel out your mistakes: for every bad deed (small or big) you commit today, immediately follow it up with a good one.",
    "Reflect on everything you are thankful for. After this, prostrate to Allah in Sajdat alShukr for your blessings.",
    "Get someone you love athoughtful gift that will benefitthem in this life and the next (atasbeeh, a dua book, etc).",
    "Wake up a little before suhoor to get into the habit of praying qiyam. If you already do this, top up your current ‘ibaadah.",
    "Be a charity superhero for the day. Go out into the public and help as many people as you possibly can!",
    "Wake up before the rest of your family so you can reap the rewards of waking them for worship.",
    "Visit any elderly relatives/ friends — learn from their wisdom and give them company",
    "Help your parents with a chore or something that they normally do.",
    "For maximum reward, recite the SubhanAllah, Alhamdulillah, Allahu Akbar (x33) after each prayer.",
    "Buy a bag of good groceries – or other treats you’d normally buy for yourself – and donate it to your local food bank or shelter.",
    "If possible, spend most of your night in the masjid supplicating and praying. If not, isolate yourself in a separate room at home.",
    "Memorize a new dua.",
    "Write out handwritten Eid cards to your closest friends and family",
    "Make an intention to fast for six days, minimum, in Shawwal — write down the days in your calendar."
  ];
  const duaOfTheDay = ["O Allah, on this day make my fasts the fasts of those who fast (sincerely), and my standing up in prayer of those who stand up in prayer (obediently), awaken me in it from the sleep of the heedless, and forgive me my sins , O God of the worlds, and forgive me, O one who forgives the sinners.",
    "O Allah, on this day, take me closer towards Your pleasure, keep me away from Your anger and punishment, grant me the opportunity to recite Your verses (of the Qur'an), by Your mercy, O the most Merciful.",
    "O Allah, on this day, grant me wisdom and awareness, keep me away from foolishness and pretention, grant me a share in every blessing You send down, by You generosity, O the most Generous.",
    "O Allah, on this day, strengthen me in carrying out Your commands, let me taste the sweetness of Your rememberance, grant me, through Your graciousness, that I give thanks to You. Protect me, with Your protection and cover, O the most discerning of those who see.",
    "O Allah, on this day, place me among those who seek forgiveness. Place me among Your righteous and obedient servants, and place me among Your close friends, by Your kindness, O the most Merciful.",
    "O Allah, on this day, do not let me abase myself by incurring Your disobedience, and do not strike me with the whip of Your punishment, keep me away from the causes of Your anger, by Your kindness and Your power, O the ultimate wish of those who desire.",
    "O Allah, on this day, help me with its fasts and prayers, and keep me away from mistakes and sins of the day, grant me that I remember You continously through the day, by Your assistance, O the Guide of those who stray.",
    "O Allah, on this day, let me have mercy on the orphans, and feed [the hungry], and spread peace, and keep company with the noble­minded, O the shelter of the hopeful.",
    "O Allah, on this day, grant me a share from Your mercy which is wide, guide me towards Your shining proofs, lead me to Your all encompassing pleasure, by Your love, O the hope of the desirous.",
    "O Allah, on this day, make me, among those who rely on You, from those who You consider successful, and place me among those who are near to you, by Your favour, O goal of the seekers.",
    "O Allah, on this day, make me love goodness, and dislike corruption and disobedience, bar me from anger and the fire [of Hell], by Your help, O the helper of those who seek help.",
    "O Allah, on this day, beautify me with covering and chastity, cover me with the clothes of contentment and chastity, let me adhere to justice and fairness, and keep me safe from all that I fear, by Your protection, O the protector of the frightened.",
    "O Allah, on this day, purify me from uncleanliness and dirt, make me patient over events that are decreed, grant me the ability to be pious, and keep company with the good, by Your help, O the beloved of the destitute.",
    "O Allah, on this day, do not condemn me for slips, make me decrease mistakes and errors, do not make me a target for afflictions and troubles, by Your honor, O the honor of the Muslims.",
    "O Allah, on this day, grant me the obedience of the humble expand my chest through the repentance of the humble, by Your security, O the shelter of the fearful.",
    "O Allah, on this day, grant me compatability with the good, keep me away from patching up with the evil, lead me in it, by Your mercy, to the permanent abode, by Your Godship, O the God of the worlds.",
    "O Allah, on this day, guide me towards righteous actions, fulfil my needs and hopes, O One who does not need explanations nor questions, O One who knows what is in the chests of the (people of the) world. Bless Muhammad and his family, the Pure.",
    "O Allah, on this day, awaken me with the blessings of its early mornings, Illuminate my heart with the brightness of its rays, let every part of my body follow its effects, by Your light, O the illuminator of the hearts of those who know.",
    "O Allah, on this day, multiply for me its blessings, and ease my path towards its bounties, do not deprive me of the acceptance of its good deeds, O the Guide towards the clear truth.",
    "O Allah, on this day, open for me the doors of the heavens, and lock the doors of Hell from me, help me to recite the Qur'an, O the One who sends down tranquility into the hearts of believers.",
    "O Allah, on this day, show me the way to win Your pleasure, do not let Shaytan have a means over me, make Paradise an abode and a resting place for me, O the One who fulfills the requests of the needy.",
    "O Allah, on this day, open for me the doors of Your Grace, send down on me its blessings, help me towards the causes of Your mercy, and give me a place in the comforts of Paradise, O the one who answers the call of the distressed.",
    "O Allah, on this day, wash away my sins, purify me from all flaws, examine my heart with (for) the piety of the hearts, O One who overlooks the shortcomings of the sinners.",
    "O Allah, on this day, I ask You for what pleases You, and I seek refuge in You from what displeases You, I ask You to grant me the opportunity to obey You and not disobey You, O One who is generous with those who ask.",
    "O Allah, on this day, make me among those who love Your friends, and hate Your enemies, following the way of Your last Prophet, O the Guardian of the hearts of the Prophets.",
    "O Allah, on this day, make my efforts worthy of appreciation, and my sins forgiven, my deeds accepted, my flaws concealed, O the best of those who hear.",
    "O Allah, on this day, bestow on me the blessings of Laylatul Qadr, change my affairs from (being) difficult to (being) easy, accept my apologies, and decrease for me [my] sins and burdens, O the Compassionate with His righteous servants.",
    "O Allah, on this day, grant me a share in its nawafil (recommended prayers), honor me by attending to my problems, make closer the means to approach You, from all the means, O One who is not preoccupied by the requests of the beseechers.",
    "O Allah, on this day, cover me with Your mercy, grant me in it success and protection, purify my heart from the darkness of false accusations, O the Merciful to His believing servants.",
    "O Allah, on this day, make my fasts worthy of appreciation and acceptance, according to what pleases You, and pleases the Messenger, the branches being strengthened by the roots, for the sake of our leader, Muhammad, and his purified family. Praise be to Allah, the Lord of the worlds."
  ]


  return (
    <RamadanDataContext.Provider
      value={{
        day,
        data,
        changeDay,
        updateDataForDay,
        tipsOfTheDay,
        duaOfTheDay,
        quotesOfTheDay,
        deedsOfTheDay,
        loading,
        setLoggedInStatus,
      }}
    >
      {children}
    </RamadanDataContext.Provider>
  );

};