import React from "react";
import { useRamadanData } from "../contexts/ramadan-data.context";

const DeedOfTheDay = () => {
  const { day, deedsOfTheDay } = useRamadanData();
  const deed = deedsOfTheDay[day - 1];

  const styles = {
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "30px", // Increase padding for better spacing
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "25px",
      textAlign: "center",
    },
    content: {
      fontSize: "14px",
      color: "#333",
      lineHeight: "1.6",
      textAlign: "center",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Deed of the Day</h2>
      <p style={styles.content}>{deed}</p>
    </div>
  );
};

export default DeedOfTheDay;
