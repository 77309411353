import React, { useEffect, useState } from "react";
import PrayerTracker from "../components/prayer-tracker.component";
import QuranTracker from "../components/quran-tracker.component";
import DailyChecklist from "../components/daily-checklist.component";
import TipOfTheDay from "../components/tip-of-the-day.component";
import QuoteOfTheDay from "../components/quote-of-the-day.component";
import DeedOfTheDay from "../components/deed-of-the-day.component";
import ReflectionsAndGems from "../components/reflection-and-gems.component";
import TodaysGoal from "../components/todays-goal.component";
import DayNavigator from "../components/day-navigator.component";
import LandingBanner from "../components/landing-banner.component";
import DuaOfTheDay from "../components/dua-of-the-day.component";

import { useRamadanData } from "../contexts/ramadan-data.context";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const { loading } = useRamadanData();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const styles = {
    mainContainer: {
      backgroundColor: "#F5F5F5",
      width: "100%",
      minHeight: "100vh",
      fontFamily: "Arial, sans-serif",
    },
    dashboardContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "1200px", // Add this line to set a maxWidth for the container
      padding: "20px",
      boxSizing: "border-box",
      margin: "0 auto", // Add this line to center the container horizontally
    },
    row: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      width: "100%",
      marginBottom: isMobile ? "0px" : "20px",
    },
    column: (unique) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: isMobile ? "0" : "40px",
      marginBottom: isMobile ? "0px" : "20px",
      flex: isMobile && unique ? "0 0 100%" : "1",
    }),
    loadingModal: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    loadingText: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#FFF",
      backgroundColor: "#000", // Change this to the desired color
      borderRadius: "5px", // Add some border-radius for a rounded corner
      padding: "10px", // Add some padding for spacing around the text
    },
  };

  useEffect(() => {
    if (!localStorage.getItem("name") || !localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);



  return (
    <div style={styles.mainContainer}>
      {loading && (
        <div style={styles.loadingModal}>
          <span style={styles.loadingText}>Updating...</span>
        </div>
      )}
      <LandingBanner />
      <div style={styles.dashboardContainer}>
        <DayNavigator />
        <div style={styles.row}>
          <div style={styles.column(true)}>
            <TipOfTheDay />
          </div>
          <div style={styles.column(true)}>
            <QuoteOfTheDay />
          </div>
          <div style={styles.column(true)}>
            <DeedOfTheDay />
          </div>
          <div style={styles.column(true)}>
            <DuaOfTheDay />
          </div>
        </div>
        <>
          <div style={styles.row}>
            <div style={styles.column(true)}>
              <PrayerTracker />
            </div>
            <div style={styles.column(true)}>
              <QuranTracker />
            </div>
            <div style={styles.column(true)}>
              <DailyChecklist />
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.column(true)}>
              <ReflectionsAndGems />
            </div>
            <div style={styles.column(true)}>
              <TodaysGoal />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Dashboard;
