import Logo from "../assets/logo.svg";
// import { useUser } from "../contexts/user.context";
import { useNavigate } from "react-router-dom";

const isMobile = window.innerWidth <= 768;

const heroBannerStyle = {
  backgroundImage: "linear-gradient(90deg, #41AA54, #2D805B)",
  color: "white",
  padding: "80px 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const heroBannerContentStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  maxWidth: "70%",
  textAlign: "left",
};

const heroBannerTextStyle = {
  fontSize: "30px",
  margin: "0",
  lineHeight: "1.2",
  marginBottom: "2px",
};

const benefitsTextStyle = {
  fontSize: "20px",
  marginBottom: "20px",
};

const buttonsContainerStyle = {
  display: "flex",
  gap: "10px",

  // add the following styles for smaller screens
  flexDirection: isMobile ? "column" : "",
  alignItems: isMobile ? "flex-start" : "",
};

const buttonStyle = {
  fontSize: "20px",
  backgroundColor: "white",
  color: "#41AA54",
  padding: "10px 20px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  textDecoration: "none",
  marginBottom: isMobile ? "5px" : "20px",
};

const logoStyle = {
  width: "250px",
  marginBottom: "1rem",
  alignSelf: "flex-start",
};

function LandingBanner() {
  // const { user, updateUser } = useUser();
  const openDonationLink = () => {
    window.open("https://square.link/u/IcZF40Mw", "_blank");
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("day");

    // Navigate to the login page
    navigate("/");
  };

  return (
    <div style={heroBannerStyle}>
      <div style={heroBannerContentStyle}>
        <img src={Logo} alt="logo" style={logoStyle} />
        {localStorage.getItem("name") ? (
          <h2 style={heroBannerTextStyle}>
            As-Salaam-Alaikum, {localStorage.getItem("name")}
          </h2>
        ) : (
          <h2 style={heroBannerTextStyle}>
            NO USER IS LOGGED IN, YOU SHOULD NOT BE SEEING THIS. REDIRECT USER
            TO LOGIN PAGE!
          </h2>
        )}
        <h1 style={heroBannerTextStyle}>Welcome to your Ramadan Journal</h1>
        <p style={benefitsTextStyle}>
          This journal is designed to help you stay organized, track your goals,
          and reflect on your spiritual journey during the holy month of
          Ramadan. If you find this Ramadan Journal helpful, please consider
          making a donation to the Masjid in the Park.
        </p>
        <div style={buttonsContainerStyle}>
          <button style={buttonStyle} onClick={openDonationLink}>
            Donate now
          </button>
          <button style={buttonStyle} onClick={handleLogout}>
            Log out
          </button>
        </div>
      </div>
    </div>
  );
}

export default LandingBanner;
