import React from "react";
import { useRamadanData } from "../contexts/ramadan-data.context";

const DailyChecklist = () => {
  const { day, data, updateDataForDay } = useRamadanData();
  const checkedItems = data[day]?.checkedItems || {};

  const handleChange = (event) => {
    updateDataForDay(day, {
      checkedItems: {
        ...checkedItems,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const tasks = [
    "Smiled at Someone",
    "Gave Charity",
    "Learned Something New",
    "Fed a Hungry Person",
    "Prayed in Congregation",
    "Read My Daily Adhkaar",
    "Helped Someone Out",
    "Asked for Forgiveness",
    "Did the Deed of the Day",
  ];

  const styles = {
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "30px", // Increase padding for better spacing
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    checklist: {
      width: "100%",
      maxWidth: "300px",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "25px",
      textAlign: "center",
    },
    listItem: {
      display: "flex",
      alignItems: "flex-start",
      fontSize: "18px",
      marginBottom: "20px",
    },
    checkbox: {
      marginRight: "5px",
      minWidth: "20px",
      minHeight: "20px",
    },
    taskText: (isChecked) => ({
      textDecoration: isChecked ? "line-through" : "none",
      textDecorationThickness: isChecked ? "4px" : "initial",
      textDecorationColor: isChecked ? "black" : "initial",
    }),
    ul: {
      listStyle: "none",
      margin: 0,
      padding: 0,
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.checklist}>
        <h1 style={styles.title}>Daily Checklist</h1>
        <p style={{ textAlign: "center", marginBottom: "25px" }}>
          Click on a task to mark it as completed. Try to complete as many tasks
          as possible every day.
        </p>
        <ul style={styles.ul}>
          {tasks.map((task) => (
            <li key={task} style={styles.listItem}>
              <label>
                <input
                  type="checkbox"
                  name={task}
                  style={styles.checkbox}
                  checked={checkedItems[task] || false}
                  onChange={handleChange}
                />
                <span style={styles.taskText(checkedItems[task])}>{task}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DailyChecklist;
