import React, { useState, useEffect } from "react";
import { useRamadanData } from "../contexts/ramadan-data.context";

const PrayerTracker = () => {
  const { day, data, updateDataForDay } = useRamadanData();
  const prayers = ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha"];
  const sunnah = "Sunnah";

  const [completedPrayers, setCompletedPrayers] = useState([]);
  const [taraweeh, setTaraweeh] = useState("");
  const [qiyam, setQiyam] = useState("");

  useEffect(() => {
    if (data[day]) {
      setCompletedPrayers(data[day].completedPrayers || []);
      setTaraweeh(data[day].taraweeh || "");
      setQiyam(data[day].qiyam || "");
    } else {
      setCompletedPrayers([]);
      setTaraweeh("");
      setQiyam("");
    }
  }, [day, data]);

  const togglePrayer = (prayer) => {
    let updatedPrayers;
    if (completedPrayers.includes(prayer)) {
      updatedPrayers = completedPrayers.filter((p) => p !== prayer);
    } else {
      updatedPrayers = [...completedPrayers, prayer];
    }
    setCompletedPrayers(updatedPrayers);
    updateDataForDay(day, { completedPrayers: updatedPrayers });
  };

  const handleTaraweehChange = (value) => {
    setTaraweeh(value);
  };

  const handleTaraweehBlur = () => {
    updateDataForDay(day, { taraweeh });
  };

  const handleQiyamChange = (value) => {
    setQiyam(value);
  };

  const handleQiyamBlur = () => {
    updateDataForDay(day, { qiyam });
  };

  const styles = {
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "30px", // Increase padding for better spacing
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "25px",
      textAlign: "center",
    },
    prayerTracker: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
    },
    prayerRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
    },
    box: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "5px",
      cursor: "pointer",
    },
    prayerBox: {
      backgroundColor: "#f1dfaa",
      width: "100px",
      height: "50px",
    },
    sunnahBox: {
      backgroundColor: "#dad5d6",
      width: "100px",
      height: "50px",
    },
    smallBox: {
      width: "100px",
      height: "100px",
    },
    boxContent: {
      fontSize: "18px",
    },
    boxLabel: {
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: "bold",
    },
    input: {
      width: "80%",
      height: "50%",
      border: "none",
      textAlign: "center",
      fontSize: "18px",
      backgroundColor: "#e1e6db",
    },
    bottomRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    completed: {
      textDecoration: "line-through",
      textDecorationThickness: "4px",
      textDecorationColor: "black",
    },
    description: {
      textAlign: "center",
      marginBottom: "20px",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Prayer Tracker</h2>
      <p style={styles.description}>
        Click on each prayer and sunnah to mark them as completed. Fill in the
        number of Taraweeh and Qiyam prayers you've performed.
      </p>
      {prayers.map((prayer) => (
        <div key={prayer} style={styles.prayerRow}>
          <div
            style={{
              ...styles.box,
              ...styles.prayerBox,
              ...(completedPrayers.includes(prayer) ? styles.completed : {}),
            }}
            onClick={() => togglePrayer(prayer)}
          >
            <div style={styles.boxContent}>{prayer}</div>
          </div>
          <div
            style={{
              ...styles.box,
              ...styles.sunnahBox,
              ...(completedPrayers.includes(prayer + sunnah)
                ? styles.completed
                : {}),
            }}
            onClick={() => togglePrayer(prayer + sunnah)}
          >
            <div style={styles.boxContent}>{sunnah}</div>
          </div>
        </div>
      ))}
      <div style={styles.bottomRow}>
        <div style={{ ...styles.box, ...styles.smallBox }}>
          <input
            type="number"
            value={taraweeh}
            onChange={(e) => handleTaraweehChange(e.target.value)}
            onBlur={handleTaraweehBlur}
            style={styles.input}
            placeholder="#"
            inputMode="numeric"
          />
          <div style={styles.boxLabel}>Taraweeh</div>
        </div>
        <div style={{ ...styles.box, ...styles.smallBox }}>
          <input
            type="number"
            value={qiyam}
            onChange={(e) => handleQiyamChange(e.target.value)}
            onBlur={handleQiyamBlur}
            style={styles.input}
            placeholder="#"
            inputMode="numeric"
          />
          <div style={styles.boxLabel}>Qiyam</div>
        </div>
      </div>
    </div>
  );
};

export default PrayerTracker;
