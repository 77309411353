import React, { useState } from "react";
import { useRamadanData } from "../contexts/ramadan-data.context";

const DayNavigator = () => {
  const { day, changeDay } = useRamadanData();
  const [isModalOpen, setIsModalOpen] = useState(false);


  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "24px",
      marginBottom: "20px",
      background: "rgba(65, 170, 84, 0.1)",
      borderRadius: "10px",
      padding: "8px",
    },
    button: {
      backgroundColor: "#4CAF50",
      color: "white",
      fontSize: "24px",
      padding: "12px 24px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      margin: "0 10px",
    },
    dayText: {
      fontWeight: "bold",
      cursor: "pointer",
    },
    helpTextContainer: {
      backgroundColor: "rgba(65, 170, 84, 0.1)",
      borderRadius: "10px",
      padding: "8px",
      marginBottom: "12px",
      textAlign: "center",
    },
    helpText: {
      fontSize: "14px",
      margin: "0",
      fontWeight: "bold",
    },
    daySelectorButton: {
      backgroundColor: "#4CAF50",
      color: "white",
      fontSize: "16px",
      padding: "6px 12px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      margin: "2px",
    },

    closeButton: {
      backgroundColor: "#4CAF50",
      color: "white",
      fontSize: "16px",
      padding: "6px 12px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      marginTop: "12px",
    },
  };

  const ModalOverlay = () => {
    return (
      <div
        style={{
          display: isModalOpen ? "block" : "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        }}
        onClick={toggleModal}
      ></div>
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const DaySelectorModal = () => {
    const days = [...Array(30).keys()].map((_, i) => i + 1);
    const dayChunks = [];

    for (let i = 0; i < days.length; i += 10) {
      dayChunks.push(days.slice(i, i + 10));
    }

    return (
      <div
        style={{
          display: isModalOpen ? "block" : "none",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "20px",
          zIndex: 1001, // Make sure it's higher than the overlay zIndex
        }}
      >

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          {days.map((dayNumber) => (
            <button
              key={dayNumber}
              style={styles.daySelectorButton}
              onClick={() => {
                changeDay(dayNumber);
                toggleModal();
              }}
            >
              {dayNumber}
            </button>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "12px",
          }}
        >
          <button onClick={toggleModal} style={styles.closeButton}>
            Close
          </button>
        </div>
      </div>
    );
  };


  return (
    <>
      <div style={styles.helpTextContainer}>
        <p style={styles.helpText}>
          Use the arrows or click on the day number to navigate quickly between
          days.
        </p>
      </div>
      <div style={styles.container}>
        {day > 1 && (
          <button style={styles.button} onClick={() => changeDay(day - 1)}>
            {"<"}
          </button>
        )}
        <span style={styles.dayText} onClick={toggleModal}>Day {day}</span>
        {day < 30 && (
          <button style={styles.button} onClick={() => changeDay(day + 1)}>
            {">"}
          </button>
        )}
      </div>
      <ModalOverlay />
      <DaySelectorModal />
    </>
  );
}

export default DayNavigator;
