import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRamadanData } from "../contexts/ramadan-data.context";

import logo from "../assets/logo.svg";

const isMobile = window.innerWidth <= 768;

const loginContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh", // Change this line
  backgroundImage: "linear-gradient(90deg, #41AA54, #2D805B)",
  padding: isMobile ? "40px" : "1rem", // Update this line
};

const buttonContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center", // Change this line
  width: "100%",
  maxWidth: "600px",
  marginBottom: "20px",
  gap: isMobile ? "20px" : "40px",
};

const buttonStyle = {
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "600px",
};

const actionButtonStyle = {
  backgroundColor: "#41AA54",
  color: "white",
  padding: "10px 20px",
  borderRadius: "4px",
  cursor: "pointer",
  alignSelf: "center",
  marginTop: "0rem",
  textDecoration: "none",
  marginBottom: "20px",
  fontSize: "16px",
};

const inputContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  marginBottom: "1rem",
};

const inputLabelStyle = {
  marginBottom: "0.5rem",
};

const inputStyle = {
  padding: "0.5rem",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginBottom: "1rem",
  textAlign: "center", // Add this line
};

const styles = {
  helpTextContainer: {
    // backgroundColor: "white",
    borderRadius: "10px",
    padding: "8px",
    marginBottom: "12px",
    textAlign: "center",
    color: "white",
  },
  helpText: {
    fontSize: "16px",
    margin: "0",
    // fontStyle: "italic",
  },
};

const tabsContainerStyle = {
  display: "flex",
  justifyContent: "center", // Change this line
  borderBottom: "2px solid white",
  marginTop: "0.5rem",
  marginBottom: "1.5rem",
};

const tabStyle = {
  cursor: "pointer",
  padding: "0.5rem 1rem",
  borderBottom: "2px solid transparent",
  backgroundColor: "#fff",
  borderRadius: "10px", // Modify this line
  flexGrow: 1, // Add this line
  textAlign: "center", // Add this line
};

const activeTabStyle = {
  ...tabStyle,
  borderBottom: "2px solid white",
  backgroundColor: "#f0f0f0",
  borderRadius: "10px", // Modify this line
};

const Login = () => {
  const navigate = useNavigate();
  const { setLoggedInStatus } = useRamadanData();
  const [isLoading, setIsLoading] = useState(false);


  // Check if token exists in localStorage
  const token = localStorage.getItem("token");
  if (token) {
    setLoggedInStatus(true);
    navigate("/dashboard");
  }

  // States for registration
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // States for login
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  // New state for active tab
  const [activeTab, setActiveTab] = useState("login");

  // New function to change active tab
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // New function to handle registration
  const handleRegisterSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const response = await fetch("https://admin.masjidinthepark.org/api/register/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // updateUser({ name: data.name });
        const token = data.token;
        const name = data.name;
        localStorage.setItem("token", token);
        localStorage.setItem("name", name);
        setLoggedInStatus(true);

        // Navigate to the dashboard
        navigate("/dashboard");
      } else {
        console.error("Registration failed:", data.error);
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
    setIsLoading(false);
  };

  // New function to handle login
  const handleLoginSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const response = await fetch("https://admin.masjidinthepark.org/api/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: loginEmail,
          password: loginPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const token = data.token;
        const name = data.name;
        const last_day = data.last_day;
        localStorage.setItem("token", token);
        localStorage.setItem("name", name);
        localStorage.setItem("day", last_day);
        setLoggedInStatus(true);

        // Navigate to the dashboard
        navigate("/dashboard");
      } else {
        console.error("Login failed:", data.error);
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
    setIsLoading(false);
  };

  return (
    <div style={loginContainerStyle}>
      <img
        src={logo}
        alt="Logo"
        style={{ width: "250px", marginBottom: "0rem" }}
      />
      <h1 style={{ fontSize: "2rem", marginBottom: "3rem", color: "white" }}>
        Ramadan Journal
      </h1>
      <div style={buttonContainerStyle}>
        <div style={buttonStyle}>
          <h2>Register or Login</h2>
          {/* Add the tabs container and tabs */}
          <div style={tabsContainerStyle}>
            <div
              style={activeTab === "login" ? activeTabStyle : tabStyle}
              onClick={() => handleTabChange("login")}
            >
              Login
            </div>
            <div
              style={activeTab === "register" ? activeTabStyle : tabStyle}
              onClick={() => handleTabChange("register")}
            >
              Register
            </div>
          </div>

          {/* Conditionally render the Register or Login form */}
          {activeTab === "register" ? (
           <form onSubmit={handleRegisterSubmit}>
           {/* Register form fields */}
           <div style={inputContainerStyle}>
             <label style={inputLabelStyle}>Name</label>
             <input
               style={inputStyle}
               type="text"
               value={name}
               onChange={(e) => setName(e.target.value)}
               autocomplete="name"
               name="name"
             />
           </div>
           <div style={inputContainerStyle}>
             <label style={inputLabelStyle}>Email</label>
             <input
               name="email"
               style={inputStyle}
               type="email"
               autocomplete="email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
             />
           </div>
           <div style={inputContainerStyle}>
             <label style={inputLabelStyle}>Password</label>
             <input
               name="new-password"
               style={inputStyle}
               type="password"
               autocomplete="new-password"
               value={password}
               onChange={(e) => setPassword(e.target.value)}
             />
           </div>
           <button style={actionButtonStyle} type="submit" disabled={isLoading}>
             {isLoading ? "Registering..." : "Register"}
           </button>
         </form>
         
          ) : (
            <form onSubmit={handleLoginSubmit}>
              {/* Login form fields */}
              <div style={inputContainerStyle}>
                <label style={inputLabelStyle}>Email</label>
                <input
                  style={inputStyle}
                  type="email"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
              </div>
              <div style={inputContainerStyle}>
                <label style={inputLabelStyle}>Password</label>
                <input
                  style={inputStyle}
                  type="password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
              </div>

              <button style={actionButtonStyle} type="submit" disabled={isLoading}>
                {isLoading ? (
                  "Logging in..."
                ) : (
                  "Login"
                )}
              </button>

            </form>
          )}
        </div>
      </div>

      <div
        style={{
          color: "white",
          textAlign: "center",
          maxWidth: "600px",
          margin: "0 auto",
          lineHeight: "1.4",
        }}
      >
        <h3>Acknowledgement</h3>
        <div style={styles.helpTextContainer}>
          <p style={styles.helpText}>
            We extend our heartfelt gratitude to Islamic Relief Canada for their inspiring {" "}
            <a
              href="https://www.islamicreliefcanada.org/resources/ramadan-for-you/agenda/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white" }}
            >
              Ramadan Action Plan
            </a>, which has greatly contributed to the creation of this Ramadan Journal. The valuable tips, quotes, deeds, and checklist items have been sourced from their Action Plan. Additionally, we appreciate
            {" "}
            <a
              href="http://www.duas.org/ramazan/Ramadhan30.htm"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white" }}
            >
              duas.org
            </a>
            {" "}for providing the daily duas included in this journal.
          </p>
        </div>
      </div>
    </div >
  );
};
export default Login;
