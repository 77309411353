import React, { useState, useEffect } from "react";
import { useRamadanData } from "../contexts/ramadan-data.context";

const TodaysGoal = () => {
  const { day, data, updateDataForDay } = useRamadanData();
  const [items, setItems] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    if (data[day]) {
      setItems(data[day].items || []);
    } else {
      setItems([]);
    }
  }, [day, data]);

  const addItem = () => {
    if (input.trim()) {
      const updatedItems = [...items, { text: input, completed: false }];
      setItems(updatedItems);
      setInput("");
      updateDataForDay(day, { items: updatedItems });
    }
  };

  const toggleComplete = (index) => {
    const updatedItems = items.map((item, idx) =>
      idx === index ? { ...item, completed: !item.completed } : item
    );
    setItems(updatedItems);
    updateDataForDay(day, { items: updatedItems });
  };

  const deleteItem = (index) => {
    const updatedItems = items.filter((_, idx) => idx !== index);
    setItems(updatedItems);
    updateDataForDay(day, { items: updatedItems });
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      addItem();
    }
  };

  const styles = {
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "30px", // Increase padding for better spacing
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "25px",
      textAlign: "center",
    },
    input: {
      width: "100%",
      marginBottom: "10px",
      fontSize: "16px",
      padding: "5px",
      // backgroundColor: "#E1E6DB",
    },
    item: {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
      backgroundColor: "#E1E6DB",
      borderRadius: "4px",
      padding: "8px",
      boxSizing: "border-box",
      width: "100%",
    },
    taskText: (completed) => ({
      textDecoration: completed ? "line-through" : "none",
      flexGrow: 1,
      textDecorationThickness: completed ? "4px" : "initial",
      textDecorationColor: completed ? "black" : "initial",
    }),
    addButton: {
      backgroundColor: "#4CAF50",
      color: "white",
      fontSize: "16px",
      padding: "8px 16px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      marginTop: "8px",
    },
    deleteButton: {
      backgroundColor: "#f44336",
      color: "white",
      fontSize: "16px",
      padding: "8px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    checkbox: {
      marginRight: "8px",
      minWidth: "20px",
      minHeight: "20px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Today's Goal</h1>
      <p style={{ textAlign: "center", marginBottom: "25px" }}>
        Set specific, achievable goals for today to help you stay focused and
        make the most of your time during Ramadan.
      </p>
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        style={styles.input}
        placeholder="Add a new goal"
      />
      <button style={styles.addButton} onClick={addItem}>
        Add
      </button>
      <ul>
        {items.map((item, index) => (
          <li key={index} style={styles.item}>
            <input
              type="checkbox"
              checked={item.completed}
              onChange={() => toggleComplete(index)}
              style={styles.checkbox}
            />
            <span
              style={styles.taskText(item.completed)}
              onClick={() => toggleComplete(index)}
            >
              {item.text}
            </span>
            <button
              style={styles.deleteButton}
              onClick={() => deleteItem(index)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TodaysGoal;
