import React, { useState, useEffect } from "react";
import { useRamadanData } from "../contexts/ramadan-data.context";

const QuranTracker = () => {
  const { day, data, updateDataForDay } = useRamadanData();
  const [quranData, setQuranData] = useState({
    verses: "",
    surahs: "",
    chapters: "",
    memorized: "",
    recited: "",
  });

  useEffect(() => {
    if (data[day] && data[day].quranData) {
      setQuranData(data[day].quranData);
    } else {
      setQuranData({
        verses: "",
        surahs: "",
        chapters: "",
        memorized: "",
        recited: "",
      });
    }
  }, [day, data]);

  const handleChange = (field, value) => {
    const updatedQuranData = { ...quranData, [field]: value };
    setQuranData(updatedQuranData);
  };

  const handleBlur = () => {
    updateDataForDay(day, { quranData });
  };

  const styles = {
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "30px 15px", // Adjust the left and right padding here
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "25px",
      textAlign: "center",
    },
    quranTracker: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    mediumBox: {
      width: "80px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px",
    },
    smallBox: {
      width: "100px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px",
    },
    boxLabel: {
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: "bold",
    },
    input: {
      width: "80%",
      height: "50%",
      border: "none",
      textAlign: "center",
      fontSize: "18px",
      backgroundColor: "#e1e6db",
    },
    topRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    bottomRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    description: {
      textAlign: "center",
      marginBottom: "20px",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Quran Tracker</h2>
      <p style={styles.description}>
        Fill in the number of verses, surahs, and chapters you've read,
        memorized, or recited.
      </p>
      <div style={styles.quranTracker}>
        <div style={styles.topRow}>
          <div style={styles.mediumBox}>
            <input
              type="number"
              value={quranData.verses}
              onChange={(e) => handleChange("verses", e.target.value)}
              onBlur={handleBlur}
              style={styles.input}
              placeholder="#"
              inputMode="numeric"
            />
            <div style={styles.boxLabel}>Verse(s)</div>
          </div>
          <div style={styles.mediumBox}>
            <input
              type="number"
              value={quranData.surahs}
              onChange={(e) => handleChange("surahs", e.target.value)}
              onBlur={handleBlur}
              style={styles.input}
              placeholder="#"
              inputMode="numeric"
            />
            <div style={styles.boxLabel}>Surah(s)</div>
          </div>
          <div style={styles.mediumBox}>
            <input
              type="number"
              value={quranData.chapters}
              onChange={(e) => handleChange("chapters", e.target.value)}
              onBlur={handleBlur}
              style={styles.input}
              placeholder="#"
              inputMode="numeric"
            />
            <div style={styles.boxLabel}>Chapter(s)</div>
          </div>
        </div>
        <div style={styles.bottomRow}>
          <div style={styles.smallBox}>
            <input
              type="number"
              value={quranData.memorized}
              onChange={(e) => handleChange("memorized", e.target.value)}
              onBlur={handleBlur}
              style={styles.input}
              placeholder="#"
              inputMode="numeric"
            />
            <div style={styles.boxLabel}>Memorized</div>
          </div>
          <div style={styles.smallBox}>
            <input
              type="number"
              value={quranData.recited}
              onChange={(e) => handleChange("recited", e.target.value)}
              onBlur={handleBlur}
              style={styles.input}
              placeholder="#"
              inputMode="numeric"
            />
            <div style={styles.boxLabel}>Recited</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuranTracker;
