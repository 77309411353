import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RamadanDataProvider } from './contexts/ramadan-data.context';
// import { UserProvider } from './contexts/user.context';
import './index.css';

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <UserProvider> */}
      <RamadanDataProvider>
        <App />
      </RamadanDataProvider>
      {/* </UserProvider> */}
    </BrowserRouter>
  </React.StrictMode>
);
