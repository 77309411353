import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../src/routes/dashboard.component';
import Login from '../src/routes/login.component';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* Add more routes as needed */}
      </Routes>
    </div>
  );
}

export default App;
