import React, { useState, useEffect } from "react";
import { useRamadanData } from "../contexts/ramadan-data.context";

const ReflectionsAndGems = () => {
  const { day, data, updateDataForDay } = useRamadanData();
  const [reflectionsAndGems, setReflectionsAndGems] = useState("");

  useEffect(() => {
    if (data[day]) {
      setReflectionsAndGems(data[day].reflectionsAndGems || "");
    } else {
      setReflectionsAndGems("");
    }
  }, [day, data]);

  const handleReflectionsAndGemsChange = (value) => {
    setReflectionsAndGems(value);
  };

  const handleReflectionsAndGemsBlur = () => {
    updateDataForDay(day, { reflectionsAndGems });
  };

  const styles = {
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "30px", // Increase padding for better spacing
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      width: "100%",
      boxSizing: "border-box",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "25px",
      textAlign: "center",
    },
    textArea: {
      width: "90%",
      height: "200px",
      border: "none",
      fontSize: "18px",
      backgroundColor: "#e1e6db",
      padding: "10px",
      marginBottom: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Reflection & Gems</h1>
      <p style={{ textAlign: "center", marginBottom: "25px" }}>
        Write down your thoughts and feelings about today's experiences, and any
        gems of wisdom you gained from the Quran or other sources.
      </p>
      <textarea
        placeholder="Reflection & Gems"
        value={reflectionsAndGems}
        onChange={(e) => handleReflectionsAndGemsChange(e.target.value)}
        onBlur={handleReflectionsAndGemsBlur}
        style={styles.textArea}
      ></textarea>
    </div>
  );
};

export default ReflectionsAndGems;
